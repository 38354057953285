<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Cursos - Categorías</h4>
            <div class="small text-muted">Administración de categorias y sub-categorias</div>
          </b-col>
          
          <b-col sm="5">
            <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Categoría">
              <i class="fa fa-plus"></i> Agregar
            </b-button>

            <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" class="d-none d-md-block" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>
          </b-col>                    
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row v-if="table.mostrarFiltros">
          <b-col sm="4">
            <b-form-group>
              <b-input-group>
                <b-form-input type="text" size="sm" placeholder="Ingrese su busqueda" v-model="table.filter"></b-form-input>
                <b-input-group-prepend>
                  <b-button variant="dark" size="sm" :disabled="!table.filter" @click="table.filter = ''">
                    <i class="fa fa-filter"></i> Filtrar
                  </b-button>
                </b-input-group-prepend>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-table class="mb-0"
                    responsive="sm"
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="table.items"
                    :fields="table.fields"
                    :filter="table.filter"
                    :current-page="table.currentPage"
                    :per-page="table.perPage"
                    selectable
                    select-mode="single"
                    @row-selected="onRowSelected"                    
                    v-if="table.items.length">                       

                <template v-slot:row-details="row">
                  <b-card>
                    <b-row>
                      <b-col md="12">
                        <b-row>
                          <b-col md="6">
                            <div class="courses-categories-titulo-subcategory mb-2">
                              <b>Categoría:</b> {{row.item.name}}
                            </div>
                          </b-col>
                          <b-col md="6">
                            <b-button @click="addSub(row.item)" type="button" variant="secondary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Sub-Categoría">
                              <i class="fa fa-plus"></i> Agregar
                            </b-button>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col md="12">
                            <b-table class="mb-0"
                                    responsive="sm"
                                    head-variant="dark"
                                    :hover="true"
                                    :small="true"
                                    :fixed="false"
                                    :items="tableSub.items"
                                    :fields="tableSub.fields"                            
                                    v-if="tableSub.items.length">   
                              
                              <template v-slot:cell(id)="data">
                                <b>{{data.item.id}}</b>
                              </template>

                              <template v-slot:cell(observations)="data">                  
                                <div class="courses-categories-tablesub-observations" v-if="data.item.courses.length">
                                  <b>Tiene ({{data.item.courses.length}}) cursos relacionados</b>
                                </div>
                                <div v-else class="text-warning">
                                  <b>No tiene cursos relacionados</b>
                                </div>
                              </template>                              
                              
                              <template v-slot:cell(f_action)="data">
                                <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                                  <b-dropdown-item @click="editSub(data.item)">
                                    <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                                  </b-dropdown-item>
                                  <b-dropdown-item @click="removeSub(data.item)">
                                    <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                                  </b-dropdown-item>
                                </b-dropdown>
                              </template>

                            </b-table>
                            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-card>
                </template>

                <template v-slot:cell(observations)="data">                  
                  <div class="courses-categories-table-observations" v-if="data.item.subcategorys.length">
                    <b>Tiene ({{data.item.subcategorys.length}}) sub-categorias relacionadas</b>
                  </div>
                  <div v-else class="text-warning">
                    <b>No tiene sub-categorias relacionadas</b>
                  </div>
                </template>

                <template v-slot:cell(id)="data">
                  <b>{{data.item.id}}</b>
                </template>

                <template v-slot:cell(f_action)="data">
                  <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                    <b-dropdown-item @click="edit(data.item)">
                      <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                    </b-dropdown-item>
                    <b-dropdown-item @click="remove(data.item)">
                      <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>

          <b-col>
            <nav>
              <b-pagination class="pull-right mb-0"
                            size="sm"
                            pills=""
                            :total-rows="getRowCount(table.items)"
                            :per-page="table.perPage"
                            v-model="table.currentPage" />
            </nav>
          </b-col>          
        </b-row>
      </b-card>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-row>
          <b-col md="12">
            <b-form-group label="Nombre">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.name"
                            required
                            placeholder="Ingresar un nombre">
              </b-form-input>
            </b-form-group>
          </b-col>         
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal>

      <!-- CRUD SUB -->
      <b-modal v-model="modal.formSub.active"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.formSub.title}}
        </div>

        <b-row>
          <b-col md="12">
            <b-form-group label="Nombre">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.formSub.name"
                            required
                            placeholder="Ingresar un nombre">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <FindObject type="coursesCategory" 
                        @select-object="loadCoursesCategory($event)" 
                        :valueID="crud.formSub.category_id"/>
          </b-col>         
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formSub.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="saveSub()">Guardar</b-button>          
        </div>
      </b-modal>
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import FindObject from '@/components/inc/find/findObject'

  export default {
    components: {
      FindObject
    },      
    data: () => {
      return {      
        access: {
          module_id: Modules.CURSOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudCategories',
          elements: {}
        }, 
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true},
            {key: 'name', label: 'Nombre'},
            {key: 'observations', label: 'Observaciones'},
            {key: 'f_action', label:''},
          ],
          filter: null,
          mostrarFiltros: false,
          currentPage: 1,
          perPage: 50,
        },
        tableSub : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true},
            {key: 'name', label: 'Nombre'},
            {key: 'observations', label: 'Observaciones'},
            {key: 'f_action', label:''},
          ],
          filter: null,
          mostrarFiltros: false,
          currentPage: 1,
          perPage: 50,
        },         
        crud: {
          form: {
            id: 0,
            name: '',            
          }, 
          formSub: {
            id: 0,
            category_id: 0,
            category: null,
            name: '',            
          },                     
        },
        modal: {
          form: {
            active: false,
            title: ''
          },
          formSub: {
            active: false,
            title: ''
          },           
        },
        arr: {
          category: [],          
        },                  
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {
      this.load()
    },
    methods: {
      getRowCount (items) {
        return items.length
      },
      onRowSelected(item) {      
        this.arr.category.forEach((element, index) => {
          if(item.length){
            if(element.id == item[0].id) {
              this.openDetail(index)
            }
          }
        }) 
      },     
      openDetail(index) {                                
        this.table.rowSelected = index
        this.load()
      },       
      load() {
        var result = serviceAPI.obtenerCategoria()

        result.then((response) => {
          var data = response.data
          this.table.items = data
          this.arr.category = data

          if(this.table.rowSelected!=null) {
            this.table.items[this.table.rowSelected]._showDetails = true       
            
            if(this.table.items[this.table.rowSelected].subcategorys) {
              this.tableSub.items = this.table.items[this.table.rowSelected].subcategorys
            }
          }            
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });        
      },
      add() {
        this.crud.form.id = 0
        this.crud.form.name = '' 
        this.modal.form.title = "Nuevo Categoría"
        this.modal.form.active = true
      },
      edit(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name           
        this.modal.form.title = "Editar Categoría"
        this.modal.form.active = true
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name        

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.form.id + ') - '+ this.crud.form.name + '?', {
          title: 'Borrar Categoría',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarCategoria(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.load()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {
        let loader = this.$loading.show();
        if (this.crud.form.id) {
          var result = serviceAPI.editarCategoria(this.crud.form);
        } else {
          var result = serviceAPI.agregarCategoria(this.crud.form);
        }

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.load()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },

      addSub(item) {
        this.crud.formSub.id = 0
        this.crud.formSub.category_id = item.id
        this.crud.formSub.name = '' 
        this.modal.formSub.title = "Nuevo Sub-Categoría"
        this.modal.formSub.active = true
      },
      editSub(item) {
        this.crud.formSub.id = item.id
        this.crud.formSub.category_id = item.category_id
        this.crud.formSub.name = item.name           
        this.modal.formSub.title = "Editar Sub-Categoría"
        this.modal.formSub.active = true
      },
      removeSub(item) {
        this.crud.formSub.id = item.id
        this.crud.formSub.name = item.name        

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.formSub.id + ') - '+ this.crud.formSub.name + '?', {
          title: 'Borrar Sub-Categoría',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarSubcategoria(this.crud.formSub.id);

            result.then((response) => {      
              this.modal.formSub.active = false        
              loader.hide()
              this.load()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      saveSub() {
        let loader = this.$loading.show();
        if (this.crud.formSub.id) {
          var result = serviceAPI.editarSubcategoria(this.crud.formSub);
        } else {
          var result = serviceAPI.agregarSubcategoria(this.crud.formSub);
        }

        result.then((response) => {
          this.modal.formSub.active = false
          loader.hide()
          this.load()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      loadCoursesCategory (object) {
        if(object){
          this.crud.formSub.category = object
          this.crud.formSub.category_id = object.id
        } else {
          this.crud.formSub.category = null
          this.crud.formSub.category_id = 0
        }
      },   
    }    
  }
</script>
<style>
  .courses-categories-titulo-subcategory {
    font-size: 17px;
  }
  .courses-categories-table-observations {
    color: darkblue;
  }
  .courses-categories-tablesub-observations {
    color: darkred;
  }
</style>